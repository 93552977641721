<template>
    <v-dialog content-class="rounded-xl" v-model="dialog">
        <v-card class="pb-3" :loading="loading">
            <v-card-title>
                <span>Método de pago</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeForm"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-alert type="error" text class="caption" v-show="stripeError">{{ stripeError }}</v-alert>
                <v-container fluid class="card mb-3" v-if="hasPaymentMethod && view == 'saved'">
                    <v-row v-for="(card, ix) in customer.cards" :key="ix" class="mb-3">
                        <v-col cols="3" class="d-flex pl-0">
                            <img :src="require(`@/assets/cards/${card.brand == '' ? 'visa' : card.brand.toLowerCase()}.svg`)" width="50" />
                        </v-col>
                        <v-col cols="6">
                            <div class="caption">᛫᛫᛫᛫ ᛫᛫᛫᛫ ᛫᛫᛫᛫ {{ card.last4 }}</div>
                            <div class="caption">Exp. {{ card.expMonth }}/{{ card.expYear }}</div>
                            <div class="caption red--text" v-show="isCardExpired">Expired</div>
                        </v-col>
                        <v-col cols="3" class="d-flex px-0">
                            <v-btn icon @click="openWarning(ix)"><v-icon small>mdi-delete</v-icon></v-btn>
                            <v-btn icon @click="view = 'new'"><v-icon small>mdi-pencil</v-icon></v-btn>
                        </v-col>
                    </v-row>

                    <v-btn block depressed class="text-none white--text" color="blue lighten-1" @click="view = 'new'">
                        <v-icon left>mdi-plus</v-icon>
                        Añadir tarjeta
                    </v-btn>
                </v-container>
                <stripe-element-card ref="stripeCard" :pk="pk" @token="tokenCreated" v-show="!hasPaymentMethod || view == 'new'"></stripe-element-card>

                <div class="caption">** Petu Power no almacena la información de tarjetas de crédito.</div>                    
            </v-card-text>
            <v-card-actions class="pb-3">
                <v-btn depressed block color="primary" class="no-uppercase" @click="submit" :disabled="(hasPaymentMethod && view != 'new') || loading">Guardar</v-btn>
            </v-card-actions>
        </v-card>
        
        <v-dialog v-model="warningDialog">
            <v-card>
                <v-card-title>
                    Warning
                    <v-spacer></v-spacer>
                    <v-btn icon @click="warningDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    Estas seguro que quieres eliminar esta tarjeta?
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed block color="error" @click="deleteCard" :loading="deletingCard">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PetuCustomer } from '@/classes/customer'
import Background from '@/components/Background'
import { _st } from '@/softech'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { StripeGateway } from '@/classes/gateways/stripe'

export default {
    name: 'PaymentMethod',
    components: { Background, StripeElementCard },
    data: () => ({
        pk              : process.env.NODE_ENV === 'production'
                            ? 'pk_live_51J4p4sEY7dPrD5c4yPfgm6WR61q4pgK6CXfndEbVeOvGnsvcLOkcuqbFIOa0PWSOJ0M6ix8iBxb1u5hE05cUnUpg00JQNHyBxH'
                            : 'pk_test_51J4p4sEY7dPrD5c4Ow5S5bHLFOiHAbLyG5Fc93AqBTtS2eiUoJ70eBb9Hjg92srTweU5Uy8M03b2HL1cSQ4cxOgq00iGxQLBEY',
        view            : 'saved',
        loading         : false,
        dialog          : false,
        stripeError     : '', 
        cardToken       : '',

        warningDialog   : false,
        deletingCard    : false,

        selectedCard    : 0,
        cardForDelete   : null,
    }),
    mounted() {
        this.$root.$on('payment-method', () => this.dialog = true)
    },
    methods: {
        ...mapActions({ updateCustomer: 'auth/updateCustomer' }),
        submit() {

            if (!this.hasPaymentMethod || this.view == 'new') {
                if( !this.loading ) 
                    this.loading = true;
                
                this.$refs.stripeCard.submit();
                return;
            }

            this.savePaymentMethod()
        },
        async createStripeCustomer({ id, email, firstName, lastName, phone }) {
            const stripe = new StripeGateway({})
            return await stripe.createCustomer({
                id,
                email,
                name: `${firstName} ${lastName}`,
                phone,
                cardToken: this.cToken
            }).stripeInfo
        },
        openWarning( ix ) {
            this.cardForDelete = ix;
            this.warningDialog = true;
        },
        async deleteCard() {
            if( this.hasPaymentMethod ) {
                try {
                    let stripe = new StripeGateway({});
                    await stripe.deleteCard({
                        customerId: this.customer.stripeCustomerId,
                        cardId: this.customer.cards[ this.cardForDelete ].stripeCardId
                    });

                    this.reloadCustomer();
                    this.cardForDelete = null;
                    this.warningDialog = false;
                }
                catch(error) {
                    console.log( error );
                    this.deletingCard = false;
                }
                // try {
                //     this.loading = true
                //     const stripe = new StripeGateway({})
                //     await stripe.deleteCard({ customerId: this.customer.stripeInfo.customerId, cardId: this.customer.stripeInfo.payment.id })
                //     this.customer.stripeInfo.payment = null
                //     const customer = new PetuCustomer(this.customer)
                //     await customer.save()
                //     await customer.load(this.customer.id)
                //     this.updateCustomer({ customer })
                //     this.view = 'new'
                //     this.$root.$emit('alert', { status: 'success', message: 'Se ha eliminado su método de pago', centered: true }) 
                // } catch (err) {
                //     console.error(err)
                //     this.stripeError = 'Ha ocurrido un error al actualizar su método de pago.'
                // } finally {
                //     this.loading = false
                // }
            }
        },
        async savePaymentMethod() {
            if( !this.loading ) 
                this.loading = true;

            let stripe = new StripeGateway({});
            let customer = new PetuCustomer();

            try {
                await customer.load(this.customer.id)
                if( _st.isNUE( customer.data.stripeCustomerId ) ) {
                    customer.data.stripeInfo = await this.createStripeCustomer(customer.data)
                } 
            
                if (_st.isNUE(customer.data.stripeInfo.payment) || _st.isNUE(customer.data.stripeInfo.payment.id) || !_st.isNUE(this.cardToken)) {
                    if (!_st.isNUE(customer.data.stripeInfo.payment) && !_st.isNUE(customer.data.stripeInfo.payment.id)) {
                        await stripe.deleteCard({ customerId: customer.data.stripeInfo.customerId, cardId: customer.data.stripeInfo.payment.id })
                    }

                    let card = await stripe.createCard({ customerId: customer.data.stripeInfo.customerId, cardToken: this.cardToken })
                    
                    if(_st.isNU(card)) {
                        this.stripeError.push('Hubo un error procesando su tarjeta.')
                        return
                    }
                    
                    customer.data.stripeInfo.payment = {
                        id          : card.id,
                        brand       : card.brand,
                        last4       : card.last4,
                        expMonth    : card.exp_month,
                        expYear     : card.exp_year
                    };
                    
                    await customer.save()
                    await customer.load(this.customer.id)
                    this.updateCustomer({ customer })
                    this.view = 'saved'
                    this.$root.$emit('alert', { status: 'success', message: 'Se ha actualizado su método de pago', centered: true }) 
                    this.dialog = false
                }
            } 
            catch (err) {
                console.error(err)
                this.stripeError = 'Ha ocurrido un error al actualizar su método de pago.'
            } 
            finally {
                this.loading = false
            }
        },
        tokenCreated(token) {
            if (!this.loading) this.loading = true
            this.cardToken = token.id
            this.savePaymentMethod()
        },
        closeForm() {
            this.dialog = false;
            this.view = 'saved';
        }
    },
    computed: {
        ...mapGetters({ customer: 'auth/customer', hasPaymentMethod: 'auth/hasPaymentMethod' }),
        cToken() {
            return _st.isEmpty(this.cardToken) ? null : this.cardToken
        },
        isCardExpired() {
            if( this.hasPaymentMethod ) {
                this.customer.cards.forEach(c => {
                    let date = new Date( c.expYear, c.expMonth, 0 );
                    if( date < ( new Date() ) )
                        return false;
                });
            } 
            else {
                return true;
            }
        },
        firstCard() {
            let card = null
            this.customer.cards.forEach(c => {
                let date = new Date( c.expYear, c.expMonth - 1, 0 );
                if( date > ( new Date() ) ) {
                    card = c;
                    return;
                }
            });

            return card;
        },
    }
}
</script>