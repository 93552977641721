<template>
    <v-dialog content-class="rounded-xl" v-model="dialog">
        <v-card class="pb-3" :loading="loading">
            <v-card-title class="justify-space-between">
                <span>Petu Pin</span>
                <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>Clave para someter ponches</v-card-subtitle>
            <v-card-text class="pb-0">
                <v-text-field v-if="customer.isAdmin" v-model="customer.pin" type="text" label="Pin" :rules="pinRules" dense></v-text-field>
            </v-card-text>
            <v-card-actions class="px-6 pb-3">
                <v-btn depressed block color="primary" class="no-uppercase" @click="submit" :disabled="!customer.pin || customer.pin === pin || loading">
                    <v-icon class="mr-3">mdi-floppy</v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PetuCustomer } from '@/classes/customer'
import { _st } from '@/softech'

export default {
    name: 'Pin',
    data: () => ({ 
        loading: false,
        dialog: false,
        pin: null,
        isUnique: true
    }),
    mounted() {
        this.$root.$on('pin', () => {
            this.pin = this.customer.pin
            this.dialog = true
        })
    },
    beforeDestroy() {
        this.$root.$off('pin')
    },
    methods: {
        ...mapActions({ updateCustomer: 'auth/updateCustomer' }),
        async submit() {
            if (!this.customer.pin || this.customer.pin === this.pin) return
            let customer = new PetuCustomer(this.customer)
            this.loading = true
            if (await customer.hasUniquePin()) {
                this.isUnique = true
                await customer.save()
                await customer.load(this.customer.id)
                this.pin = this.customer.pin
                this.updateCustomer({ customer })
                this.dialog = false
                this.$root.$emit('alert', { status: 'success', message: 'Pin ha sido actualizado' })
            } else {
                this.isUnique = false
                this.$root.$emit('alert', { status: 'error', message: 'Pin ya está reservado' })
            }
            this.loading = false
        },

    },
    computed: {
        ...mapGetters({ customer: 'auth/customer' }),
        pinRules() {
            const rules = [v => !!v || 'Campo es requerido']
            if (!this.isUnique) rules.push(v => 'Pin ya está reservado')
            return rules
        }
    }
}
</script>