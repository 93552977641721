<template>
    <v-dialog content-class="rounded-xl" v-model="dialog">
        <v-card class="pb-3" :loading="loading">
            <v-card-title class="justify-space-between">
                <span>Vacu ID</span>
                <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>Evidencia de vacunación</v-card-subtitle>
            <v-card-text class="pb-0">
                <v-img v-if="image.imgUrl" :src="image.imgUrl" contain max-height="200"></v-img>
                <div v-else class="d-flex align-center">
                    <v-icon small class="mr-2"  color="orange">mdi-alert-outline</v-icon>
                    <span>No ha subido Vacu ID</span>
                </div>
                <v-file-input v-show="false" v-model="image" @change="encodeImageFileAsURL" accept="image/*" ref="image"></v-file-input>
            </v-card-text>
            <v-card-actions class="px-6 pb-3">
                <div class="w-100">
                <v-btn @click="$refs.image.$refs.input.click()" color="secondary" block class="text-none mb-3">
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    Subir Vacu ID
                </v-btn>
                <v-btn depressed block color="primary" class="text-none" @click="submit" :disabled="!image.imgUrl || loading">
                    <v-icon class="mr-2">mdi-floppy</v-icon>
                    Guardar
                </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PetuCustomer } from '@/classes/customer'

export default {
    name: 'VacuID',
    data: () => ({ 
        loading: false,
        dialog: false,
        image: { imgUrl: '' },
        pinRules: [ v => !!v || 'Campo es requerido' ],
    }),
    async mounted() {
        this.$root.$on('vacu-id', () => this.dialog = true)
        let customer = new PetuCustomer( this.customer );
        await customer.getVacuIDSrc();
        this.image = customer.vacuIdImage;
    },
    beforeDestroy() {
        this.$root.$off('vacu-id')
    },
    methods: {
        ...mapActions({ updateCustomer: 'auth/updateCustomer' }),
        encodeImageFileAsURL() {
            var reader = new FileReader()
            reader.onloadend = () => {
                this.image = {
                    name    : this.image.name,
                    size    : (this.image.size / 1024 / 1024) < 1 ? Math.round(this.image.size / 1024) + ' KB' : (this.image.size / 1024 / 1024).toFixed(2) + ' MB',
                    imgUrl  : reader.result,
                    data    : this.image,
                    primary : true,
                    order   : 0,
                    isNew   : true,
                }
            }
            reader.readAsDataURL(this.image)
        },
        async submit() {
            console.log(this.image);
            if( !this.image.imgUrl ) 
                return;

            let customer = new PetuCustomer( this.customer );

            this.loading = true;
            customer.vacuIdImage = this.image;
            await customer.save()
            await customer.load( this.customer.id );
            this.loading = false;
            this.updateCustomer({ customer });

            this.dialog = false;
        },

    },
    computed: {
        ...mapGetters({ customer: 'auth/customer' }),
    }
}
</script>