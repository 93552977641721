<template>
    <v-row>
        <background page="profile"></background>
        <v-col class="pa-5 my-5" cols="12">
            <v-card v-if="!loading" class="rounded-xl" style="padding-top: 75px">
                <v-card-text class="text-center py-0" style="position:absolute;top: -25px">
                    <v-avatar size="100" color="accent" class="avatar white--text elevation-5">
                        <h1 v-if="!profilePic">{{ initials }}</h1>
                        <v-img v-else :src="profilePic"></v-img>
                    </v-avatar>
                </v-card-text>
                <v-card-text class="text-right" style="position:absolute;top: 0">
                    <v-btn to="/perfil/editar" icon><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-text>
                <v-card-title class="justify-center">{{ customer.firstName }} {{ customer.lastName }}</v-card-title>
                <v-card-subtitle v-if="customer.height || customer.weight" class="text-center">
                    <span v-if="customer.height">{{ customer.height }}</span><span v-if="customer.height && customer.weight" class="mx-2">·</span><span v-if="customer.weight">{{ customer.weight }}lbs</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-list two-line>
                        <!-- weight goal -->
                        <v-list-item class="pr-0" v-if="customer.weightGoal">
                            <v-list-item-icon><v-icon color="primary">mdi-star</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customer.weightGoal }} lbs</v-list-item-title>
                                <v-list-item-subtitle>Peso meta</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="customer.weightGoal" inset></v-divider>
                        <!-- phone -->
                        <v-list-item class="pr-0" v-if="customer.phone">
                            <v-list-item-icon><v-icon color="primary">mdi-phone</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customer.phone }}</v-list-item-title>
                                <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="customer.email" inset></v-divider>
                        <!-- email -->
                        <v-list-item class="pr-0" v-if="customer.email">
                            <v-list-item-icon><v-icon color="primary">mdi-email</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customer.email }}</v-list-item-title>
                                <v-list-item-subtitle>Email</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="customer.email || customer.phone" inset></v-divider>
                        <!-- strikes -->
                        <v-list-item class="pr-0">
                            <v-list-item-icon><v-icon color="primary">mdi-numeric</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customer.strikes == null ? 0 : customer.strikes }}</v-list-item-title>
                                <v-list-item-subtitle>Strikes</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider inset v-if="!isAddressEmpty(customer.billingAddress)"></v-divider>
                        <!-- billing address -->
                        <v-list-item class="pr-0" v-if="!isAddressEmpty(customer.billingAddress)">
                            <v-list-item-icon><v-icon color="primary">mdi-receipt</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title><address-component v-model="customer.billingAddress"></address-component></v-list-item-title>
                                <v-list-item-subtitle>Billing</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider inset v-if="!isAddressEmpty(customer.shippingAddress)"></v-divider>
                        <!-- shipping address -->
                        <v-list-item class="pr-0" v-if="!isAddressEmpty(customer.shippingAddress)">
                            <v-list-item-icon><v-icon color="primary">mdi-package-variant-closed</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title><address-component v-model="customer.shippingAddress"></address-component></v-list-item-title>
                                <v-list-item-subtitle>Shipping</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <!-- vacuID -->
                        <v-list-item class="pr-0">
                            <v-list-item-icon><v-icon color="primary">mdi-virus</v-icon></v-list-item-icon>
                            <v-list-item-content >
                                <v-list-item-title>
                                    <div class="d-flex align-center">
                                        <v-icon small class="mr-2"  :color="vacuIDIconColor" v-text="vacuIDIcon"></v-icon>
                                        <span>{{ vacuIDText }}</span>
                                    </div>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <div @click="$root.$emit('vacu-id')" class="link petu-pink mt-3" v-text="vacuIDLinkText"></div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <!-- payment method -->
                        <v-list-item class="pr-0">
                            <v-list-item-icon><v-icon color="primary">mdi-credit-card</v-icon></v-list-item-icon>
                            <v-list-item-content >
                                <v-list-item-title>
                                    <div v-if="hasPaymentMethod">᛫᛫᛫᛫ ᛫᛫᛫᛫ ᛫᛫᛫᛫ {{ firstCard.last4 }}</div>
                                    <div v-else class="d-flex align-center">
                                        <v-icon small class="mr-2"  color="orange">mdi-alert-outline</v-icon>
                                        <span>Aún no tiene método de pago</span>
                                    </div>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <div v-if="hasPaymentMethod">
                                        <div>Exp. {{ firstCard.expMonth }}/{{ firstCard.expYear }}</div>
                                        <div class="caption red--text" v-if="isCardExpired">Expirado</div>
                                    </div>
                                    <div @click="$root.$emit('payment-method')" class="link petu-pink mt-3" v-text="paymentMethodLinkText"></div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <!-- pin -->
                        <v-list-item v-if="customer.isAdmin" class="pr-0">
                            <v-list-item-icon><v-icon color="primary">mdi-lock</v-icon></v-list-item-icon>
                            <v-list-item-content >
                                <v-list-item-title>
                                    <div v-if="customer.pin" class="d-flex align-center">
                                        <v-btn @click="showPin = !showPin" icon small class="mr-1"><v-icon small v-text="pinIcon"></v-icon></v-btn>
                                        <div v-if="showPin">{{ customer.pin }}</div>
                                        <div v-else>᛫᛫᛫᛫᛫᛫</div>
                                    </div>
                                    <div v-else class="d-flex align-center">
                                        <v-icon small class="mr-2"  color="orange">mdi-alert-outline</v-icon>
                                        <span>Aún no tiene pin</span>
                                    </div>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <div @click="$root.$emit('pin')" class="link petu-pink mt-3" v-text="pinLinkText"></div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider class="mb-5"></v-divider>
                    <!-- subscriptions -->
                    <div class="subtitle-1">Suscripciones</div>
                    <div v-if="!loading && subscriptions.length > 0">
                        <v-list>
                            <v-list-item v-for="s in subscriptions" :key="s.id" class="px-0" two-line>
                                <v-list-item-content>
                                    <v-list-item-title><router-link :to="`/producto/${s.product.slug}`" class="black--text link">{{ getProductName(s.productId) }}</router-link></v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-icon v-if="isExpired(s.nextPaymentDate)" class="mr-1" color="red" small>mdi-alert-circle-outline</v-icon>
                                        <span>{{ getExpiryDateText(s.nextPaymentDate) }}</span>
                                        <div v-if="!isOnline(s.productId) && s.remainingSessions">{{ s.remainingSessions }} {{ s.remainingSessions > 1 ? 'sesiones faltantes' : 'sesión faltante' }}</div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="hasPaymentMethod && !isCardExpired && isRenewable(s.productId)">
                                    <v-switch v-if="!isExpired(s.nextPaymentDate)" v-model="s.autoRenew" @change="saveSubscription(s)"></v-switch>
                                    <v-btn v-else @click="addToCart(s)" class="no-uppercase" color="primary" small  text>Renovar<v-icon small class="ml-2">mdi-autorenew</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <router-link to="/suscripciones" class="link">Ver suscripciones</router-link>
                        </v-list>
                    </div>
                    <div v-else>Aun no tiene suscripciones</div>
                    <v-divider class="my-5"></v-divider>
                    <!-- orders -->
                    <div class="subtitle-1">Órdenes</div>
                    <div v-if="orders.length > 0">
                        <v-list>
                            <v-list-item v-for="o in orders" :key="o.id" class="px-0" :to="`/ordenes/${o.id}`" three-line>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ o.orderNumber.toUpperCase() }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ toMoney(o.total) }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        {{ formatDate( o.orderDate, 'MMM DD, YYYY @ hh:mm a' ) }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-chip :color="getOrderStatusColor(o.status)" dark small>{{ o.status }}</v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>

                        <router-link to="/ordenes" class="link">Ver órdenes</router-link>
                    </div>
                    <div v-else>Aun no tiene órdenes</div>
                    <v-divider class="my-5"></v-divider>
                    <!-- check ins -->
                    <div class="subtitle-1">Check-ins</div>
                    <div v-if="checkIns.length > 0">
                        <v-list>
                            <v-list-item v-for="cI in checkIns" :key="cI.id" class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title>{{ cI.session.name }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="secondary--text">{{ cI.session.type }}</span> | {{ formatDate( cI.checkinDate, 'MMM DD, YYYY @ hh:mm a' ) }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <router-link to="/checkins" class="link">Ver check-ins</router-link>
                    </div>
                    <div v-else>Aun no tiene checkins</div>
                </v-card-text>
            </v-card>
            <VacuID></VacuID>
            <payment-method></payment-method>
            <pin></pin>
            <v-dialog v-model="isLoading" hide-overlay persistent content-class="rounded-lg" width="300">
                <v-card class="petu-green-bg" dark>
                    <v-card-text> 
                        <span v-if="loading">Cargando datos</span>
                        <span v-else>Renovando suscripción</span>
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PetuProduct } from '@/classes/product';
import { PetuCustomer } from '@/classes/customer';
import { PetuSubscription } from '@/classes/subscription';

import { TrainingType } from '@/models';

import AddressComponent from '@/components/AddressComponent';
import VacuID from '@/components/VacuID.vue';
import PaymentMethod from '@/components/PaymentMethod';
import Pin from '@/components/Pin';

import Background from '@/components/Background';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    name: 'Profile',
    components: { AddressComponent, Background, PaymentMethod, Pin, VacuID },
    props: { id: { type: String, default: '' }},
    data: () => ({
        loading: true,
        renewLoading: false,
        products: [],
        subscriptions: [],
        checkIns: [],
        sessions: [],
        orders: [],
        showPin: false,
    }),
    async mounted() {
        let customer        = new PetuCustomer(this.customer)
        this.subscriptions  = await customer.getSubscriptions(3);
        this.checkIns       = await customer.getCheckIns(3);
        this.orders         = await customer.getOrders(3);

        this.products = await this.getProducts();
        this.loading = false;
    },
    methods: {
        ...mapActions({ updateCustomer: 'auth/updateCustomer', addToCartAction: 'cartInfo/addToCart' }),
        isExpired(date) {
            date = new Date(date)
            return date < (new Date())
        },
        async getProducts() {
            if (_st.isNU( this.subscriptions)) return []

            let products = [];
            for (const subscription of this.subscriptions) {
                let product = new PetuProduct();
                await product.load( subscription.productId );
                products.push( product.data );
            }

            return products;
        },
        getProductName(id) { 
            const p = this.products.find((p) => p.id === id)
            return p ? p.name : ''
        },
        getExpiryDateText(date) {
            let verb = this.isExpired(date) ? 'Expiró' : 'Expira' 
            return `${verb} en ${(new Date(date)).toLocaleDateString()}` 
        },
        async saveSubscription(subscription) {
            try {
                subscription = new PetuSubscription(subscription)
                await subscription.save()
                let customer = new PetuCustomer(this.customer)
                await customer.load(this.customer.id)
                this.saving = false
                this.updateCustomer({ customer })
                this.$root.$emit('alert', { status: 'success', message: 'Su suscripción ha sido guardada exitosamente', centered: true })
            } catch (error) {
                console.log(error)
                this.$root.$emit('alert', { status: 'error', message: 'Ha ocurrido un error', centered: true })
            }
        },
        async addToCart(subscription) {
            try {
                if (!this.isExpired(subscription.nextPaymentDate)) return
                this.renewLoading = true
                await this.addToCartAction({ productId: subscription.productId, qty: 1 })
                this.$router.push('/checkout')
            } catch(err) {
                console.log(err)
                this.renewLoading = false
                this.$root.$emit('alert', { status: 'error', message: 'Ha ocurrido un error', centered: true })
            }
        },
        isRenewable(productId) {
            const i = this.products.findIndex((p) => p.id === productId)
            if (i === -1) return false
            const trainingType = this.products[i].trainingType
            switch (trainingType) {
                case TrainingType.ONLINE:
                case TrainingType.ONE_ON_ONE:
                case TrainingType.PRESENCIAL: return true
                default: return false
            }
        },
        toMoney( amount ) {
            return _st.toMoney( amount )
        },
        getOrderStatusColor( status ) {
            switch( status ) {
                case 'processing'       : return 'orange';
                case 'completed'        : return 'green';
                case 'cancelled'        : return 'blue-grey';
                case 'pending payment'   : return 'amber';
                case 'shipped'          : return 'teal';
                case 'failed'           : return 'red';
                case 'draft'            : return 'grey';
                case 'refunded'         : return 'purple';
            }
        },
        formatDate( d, format ) {
            return moment(d).format(format);
        },
        isAddressEmpty(addr) {
            return addr
            ? !addr.line1 && !addr.line2 && !addr.city && !addr.state && !addr.zipCode
            : false
        },
        isOnline(id) {
            return this.products.find((p) => p.id === id).trainingType === 'Online'
        },
    },
    computed: {
        ...mapGetters({ 
            customer            : 'auth/customer', 
            profilePic          : 'auth/profilePic', 
            hasVacuID           : 'auth/hasVacuID', 
            hasPaymentMethod    : 'auth/hasPaymentMethod' 
        }),
        isLoading() { return this.loading || this.renewLoading },
        initials() { return (new PetuCustomer(this.customer)).getInitials() },
        isCardExpired() {
            if( this.hasPaymentMethod ) {
                this.customer.cards.forEach(c => {
                    let date = new Date( c.expYear, c.expMonth, 0 );
                    if( date < ( new Date() ) )
                        return false;
                });
            } 
            else {
                return true;
            }
        },
        firstCard() {
            let card = null
            this.customer.cards.forEach(c => {
                let date = new Date( c.expYear, c.expMonth - 1, 0 );
                if( date > ( new Date() ) ) {
                    card = c;
                    return;
                }
            });

            return card;
        },
        
        vacuIDText:({ hasVacuID }) => hasVacuID ? 'Ya ha subido su Vacu ID' : 'Aún no ha subido su Vacu ID',
        vacuIDLinkText:({ hasVacuID }) => hasVacuID ? 'Editar Vacu ID' : 'Subir Vacu ID',
        vacuIDIcon: ({ hasVacuID }) => hasVacuID ? 'mdi-check-circle-outline' : 'mdi-alert-outline',
        vacuIDIconColor: ({ hasVacuID }) => hasVacuID ? 'success darken-1' : 'orange',
        paymentMethodLinkText:({ hasPaymentMethod }) => hasPaymentMethod ? 'Editar método de pago' : 'Añadir método de pago',
        pinLinkText:({ customer }) => customer.pin ? 'Editar pin' : 'Añadir pin',
        pinIcon: ({ showPin }) => showPin ? 'mdi-eye-off' : 'mdi-eye',
    }
}
</script>